import React from 'react'
// import Header from '../components/header'
// import Footer from '../components/footer'
import SustainabilityMainBanner from '../templates/Sustainability/sustainabilityMainBanner'
import SustainabilityEmpowerCommunity from '../templates/Sustainability/sustainabilityEmpowerCommunity'
import SustainableInitiatives from '../templates/Sustainability/sustainableInitiatives'
import SustainableSolutions from '../templates/Sustainability/sustainableSolutions'
import SustainableEconomic from '../templates/Sustainability/sustainableEconomic'
import SustainabilityBuildingFuture from '../templates/Sustainability/sustainabilityBuildingFuture'
import SustainabilityInnovativeSolutions from '../templates/Sustainability/sustainabilityInnovativeSolutions'
import SustainabilityJourney from '../templates/Sustainability/sustainabilityJourney'
// import Layout from "../components/layout"

export default function Sustainability() {
  return (
    <div>
        {/* <Header /> */}
        {/* <Layout> */}
        <SustainabilityMainBanner />
        <SustainabilityEmpowerCommunity />
        <SustainableInitiatives />
        <SustainableSolutions />
        <SustainableEconomic />
        <SustainabilityBuildingFuture />
        <SustainabilityInnovativeSolutions />
        <SustainabilityJourney />
        {/* <Footer /> */}
        {/* </Layout> */}
    </div>
  )
}
